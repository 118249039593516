<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="providers"
      :headers="headers"
      :slots="['item.Tipo']"
      :actions="actions"
    >
      <template
        v-if="$user.get().role == 'gestor' || $user.get().role == 'consultoria'"
        v-slot:btnCadastro
      >
        <router-link :to="`/${$user.get().role}/prestador/form`">
          <v-btn color="success"> Novo prestador </v-btn>
        </router-link>
      </template>
      <template v-slot:[`item.Tipo`]="{ item }">
        {{ typeProvider(item.Tipo) }}
      </template>
    </crud-list>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      actions: [
        {
          title: 'Editar prestador',
          color: 'yellow darken-3',
          click: item =>
            this.$router.push({
              path: `/${this.$user.get().role}/prestador/form`,
              query: {
                id: item.id,
              },
            }),
          icon: 'mdi-pencil',
        },
        {
          title: 'Inativar prestador',
          color: 'red darken-3',
          click: item => this.deleteItem(item),
          icon: 'mdi-delete',
        },
      ],
      providers: [],
      loading: true,
      headers: [
        {
          text: '',
          sortable: false,
          width: '80px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Nome / Nome Fantasia',
          align: 'left',
          sortable: true,
          value: 'Nome',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'Email',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'CPF / CNPJ',
          align: 'left',
          sortable: true,
          value: 'CPF_CNPJ',
          width: 'auto',
        },
        {
          text: 'Tipo',
          align: 'left',
          sortable: true,
          value: 'Tipo',
          width: 'auto',
        },
      ],
    }
  },
  created() {
    this.getProviders()
  },
  methods: {
    typeProvider(tipo) {
      if (tipo) return 'Pessoa Física'
      return 'Pessoa Jurídica'
    },
    getProviders() {
      this.api.get.prestadores().then(data => {
        this.providers = data
        this.loading = false
      })
    },
    deleteItem(item) {
      this.Swal.fire({
        icon: 'error',
        title: 'Inativar Prestador',
        text: 'Você tem certeza que deseja inativar este prestador?',
        showCancelButton: true,
        confirmButtonColor: '#f74242',
        confirmButtonText: 'SIM, INATIVAR',
        cancelButtonText: 'NÃO',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.api.delete.prestador(item.id).then(
            () => {
              return 1
            },
            reject => {
              return reject
            },
          )
        },
        allowOutsideClick: () => !this.Swal.isLoading(),
      }).then(result => {
        if (result.value === 1) {
          this.loading = true
          this.getProviders()
          this.Swal.fire({
            title: 'Sucesso',
            text: 'O prestador foi inativado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          })
        }
      })
    },
  },
}
</script>
